/* src/LandingPage.css */
.landing-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
  padding: 0 20px;
  text-align: center;
}

.intro h1 {
  font-size: 3rem;
  color: #333;
  margin-bottom: 0.5em;
}

.intro p {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 1.5em;
}

.social-links {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.social-icon {
  font-size: 2rem;
  color: #333;
  transition: color 0.3s ease;
}

.social-icon:hover {
  /* You can customize each icon’s hover color if desired */
  color: #0077b5;
  /* Example: LinkedIn blue */
}