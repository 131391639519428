/* MainPage.css */

html {
  scroll-behavior: smooth;
  /* Smooth scrolling for anchor links */
}

.main-page {
  height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  scroll-snap-align: start;
  /* Snaps to the beginning of the section */
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 1200px;
  background-color: #fff;
}