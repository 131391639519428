.contact-page {
  width: 100%;
  padding: 40px;
  background-color: #fff;
  color: #333;
  text-align: left;
}

.contact-page h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  margin: 10px 0 5px;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.contact-form button {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1.2rem;
  margin: 20px 0px;
}

.contact-form button:hover {
  background-color: #555;
}